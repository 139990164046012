<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> UPDATE KATEGORI MEMBER</v-card-title>
        <form @submit.prevent="CreateData" ref="form">
          <v-row>
            <v-col cols="12" v-if="errors.length">
              <v-alert color="error" v-for="error in errors" :key="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning"> </v-icon>
                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                label="Nama Kategori Member"
                v-model="kategorimembers.nama_kategorimember"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-header>Aktif</v-header>
              <v-radio-group v-model="kategorimembers.aktif" column>
                <v-radio label="Yes" value="Y" color="info"></v-radio>
                <v-radio label="No" value="T" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Update </v-btn>

              <router-link :to="{ name: 'kategori-members-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiCheckBold, mdiSlashForward } from '@mdi/js'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Kategori Members',
          disabled: false,
          href: '/admin/kategori-members',
        },
        {
          text: 'Update Kategori Members',
          disabled: true,
        },
      ],

      icons: {
        mdiCheckBold,
        mdiSlashForward,
      },

      kategorimembers: {
        nama_kategorimember: '',
        aktif: 'Y',
      },
      errors: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],
    }
  },
  mounted() {
    this.$isLoading(true)
    setTimeout(() => {
      this.getAllUsers()
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    getAllUsers() {
      // let uri = `/api/admin/roles/${this.$route.params.id_role}/show`
      let uriUsers =
        process.env.VUE_APP_ROOT_API + `/api/admin/kategorimember/${this.$route.params.id_kategorimember}/show`
      this.axios.get(uriUsers).then(response => {
        this.kategorimembers = response.data.kategorimember
      })
    },

    CreateData(e) {
      // console.log('ID_ROLE: ' + this.users.id_role)

      if (this.$data.kategorimembers.nama_kategorimember.length === 0) {
        // this.errors.push('Nama Role harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Nama kategori member wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.kategorimembers.aktif.length === 0) {
        // this.errors.push('Email harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Aktif wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        let uri = process.env.VUE_APP_ROOT_API + `/api/admin/kategorimember/${this.$route.params.id_kategorimember}`
        this.axios
          .post(uri, this.kategorimembers)
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Kategori member update successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })

            // this.$router.push({ name: 'users-index' })
          })
          .catch(e => {
            this.$swal.fire({
              title: 'Error!',
              html: this.kategorimembers.nama_kategorimember + ' sudah digunakan !',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            })
          })

        e.preventDefault()
        setTimeout(() => {
          this.$router.push({ name: 'kategori-members-index' })
        }, 3000)
      }
    },
  },
}
</script>
